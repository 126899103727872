<app-header *ngIf="_isLoggedIn"></app-header>
<app-side-nav [breakpointObserver]="breakpointObserver" *ngIf="_isLoggedIn" ></app-side-nav>
<div class="content">
    <router-outlet *ngIf="!_isLoggedIn"></router-outlet>
</div>





