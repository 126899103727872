import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isLoggedIn = this.auth.isAuthenticated();
        const isApiUrl = (
            request.url.startsWith(environment.userUrl) 
            || request.url.startsWith(environment.coreUrl)
            || request.url.startsWith(environment.companyUrl)
            || request.url.startsWith(environment.dmsUrl)
            || request.url.startsWith(environment.contactUrl)
            || request.url.startsWith(environment.financingUrl))
            && !request.url.includes("/onboarding")
            && !request.url.includes("/login")

        if (isLoggedIn && isApiUrl) {
            console.log("add header")
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this.auth.token()}` }
            });
        }

        return next.handle(request);
    }
}