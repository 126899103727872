import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  
  constructor(readonly auth: AuthService, private router: Router, private dialog: MatDialog) {}

  // public prompt(returnUrl: string) {
  //   this.dialog.open(LoginComponent, { data: {action:'welcome', returnUrl: returnUrl}, disableClose: true })
  // }
 
  public disconnect(jumpTo = '/login') {
    this.auth.signOut()
    this.router.navigateByUrl(jumpTo);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isAuthenticated()) {
      return true;
    } else {
      this.disconnect()
      return false;
    }
  }

}